import React, { useState } from 'react';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import { useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import { logout } from '../../../redux/actions/authActions';
import logo from '../../../shared/assets/logo.svg';
import logo2 from '../../../shared/assets/logo.png';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoIcon from '@mui/icons-material/Info';
import NotificationsComponent from '../NotificationsComponent';
import { fetchNotifications } from '../../../redux/actions/notificationsActions';

const MenuDrawer: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const [open, setOpen] = useState(true);
  const [manualToggle, setManualToggle] = useState(false);
  const userId = useSelector((state: RootState) => state.auth.user?.id);

  const [anchorElNotifications, setAnchorElNotifications] = React.useState<null | HTMLElement>(null);
  const notifications = useSelector((state: RootState) => state.notifications.notifications);

  const toggleDrawer = () => {
    setOpen(!open);
    setManualToggle(!open);
  };

  const handleMenuClick = () => {
    if (!manualToggle) return;
  };

  const handleOpenNotificationsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleCloseNotificationsMenu = () => {
    setAnchorElNotifications(null);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  React.useEffect(() => {
    dispatch(fetchNotifications(userId || 0));
  }, [dispatch, userId]);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: open ? 180 : 50,
        transition: 'width 0.3s ease',
        flexShrink: 0
      }}
    >
      <Box sx={{ justifyContent: 'center', backgroundColor: '#F5F6FA', borderBottom: '1px solid #fff', color: 'black' }} >
        {open && (
          <>
            <Toolbar sx={{ height: 50 }}>
              <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                <img src={logo} alt="AppStudio" title="AppStudio" style={{ height: 40 }} />
              </Link>
            </Toolbar>
            <Divider sx={{ borderColor: '#242930' }} />
            <Toolbar sx={{ justifyContent: 'center', mb: 1 }}>
            <Typography
              variant="body2"
              align="center"
              sx={{
                fontWeight: 'bold',
                color: 'black',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis', // Ajoute "..."
                maxWidth: '100px',
                display: 'block'
              }}
            >
              {user?.email || "Utilisateur"}
            </Typography>
            </Toolbar>
          </>
        )}
        {!open && (
          <>
            <Toolbar sx={{ height: 50 }}>
              <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                <img src={logo2} alt="AppStudio" title="AppStudio" style={{ height: 25 }} />
              </Link>
            </Toolbar>
          </>
        )}
      </Box>

      <Divider sx={{ borderColor: 'white' }} />

      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {/* Liste des éléments du menu */}
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/" onClick={handleMenuClick}>
              <ListItemIcon sx={{ minWidth: 'auto', justifyContent: 'center', alignItems: 'center', width: open ? 'auto' : 50, mr: open ? 2 : 0 }}>
                <HomeIcon sx={{ color: '#e5e7eb', fontSize: open ? 24 : 20 }} />
              </ListItemIcon>
              {open && <ListItemText primary="Tableau de bord" />}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/quote" onClick={handleMenuClick}>
              <ListItemIcon sx={{ minWidth: 'auto', justifyContent: 'center', alignItems: 'center', width: open ? 'auto' : 50, mr: open ? 2 : 0 }}>
                <AssignmentIcon sx={{ color: '#e5e7eb', fontSize: open ? 24 : 20 }} />
              </ListItemIcon>
              {open && <ListItemText primary="Devis" />}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/customers" onClick={handleMenuClick}>
              <ListItemIcon sx={{ minWidth: 'auto', justifyContent: 'center', alignItems: 'center', width: open ? 'auto' : 50, mr: open ? 2 : 0 }}>
                <PeopleIcon sx={{ color: '#e5e7eb', fontSize: open ? 24 : 20 }} />
              </ListItemIcon>
              {open && <ListItemText primary="Clients" />}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/services" onClick={handleMenuClick}>
              <ListItemIcon sx={{ minWidth: 'auto', justifyContent: 'center', alignItems: 'center', width: open ? 'auto' : 50, mr: open ? 2 : 0 }}>
                <BuildIcon sx={{ color: '#e5e7eb', fontSize: open ? 24 : 20 }} />
              </ListItemIcon>
              {open && <ListItemText primary="Services" />}
            </ListItemButton>
          </ListItem>
        </List>

        <Divider sx={{ borderColor: 'white', marginTop: 'auto' }} />

        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/parameters" onClick={handleMenuClick}>
              <ListItemIcon sx={{ minWidth: 'auto', justifyContent: 'center', alignItems: 'center', width: open ? 'auto' : 50, mr: open ? 2 : 0 }}>
                <SettingsIcon sx={{ color: '#e5e7eb', fontSize: open ? 24 : 20 }} />
              </ListItemIcon>
              {open && <ListItemText primary="Paramètres" />}
            </ListItemButton>
          </ListItem>
        </List>

        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'flex-end', marginTop: 'auto' }}>
          <Toolbar sx={{ justifyContent: 'center', flexDirection: open ? 'row' : 'column', alignItems: open ? 'center' : 'flex-start' }}>
            <IconButton onClick={handleOpenNotificationsMenu}>
              <Badge color="primary" badgeContent={notifications.length}>
                <NotificationsIcon sx={{ color: '#e5e7eb', fontSize: open ? 24 : 20 }} />
              </Badge>
            </IconButton>
            <IconButton aria-label="A propos" component={Link} to="/aboutus">
              <InfoIcon sx={{ color: '#e5e7eb', fontSize: open ? 24 : 20 }} />
            </IconButton>
            <IconButton aria-label="Déconnexion" onClick={handleLogout}>
              <LogoutIcon sx={{ color: '#e5e7eb', fontSize: open ? 24 : 20 }} />
            </IconButton>
          </Toolbar>

          <Popover
            anchorEl={anchorElNotifications}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElNotifications)}
            onClose={handleCloseNotificationsMenu}
            disableEnforceFocus
          >
            <Box sx={{ p: 2, minWidth: 250 }}>
              <NotificationsComponent />
            </Box>
          </Popover>

          <Divider sx={{ borderColor: 'white' }} />

          <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 2 }}>
            <IconButton onClick={toggleDrawer}>
              {open ? <ChevronLeft sx={{ color: '#e5e7eb' }} /> : <ChevronRight sx={{ color: '#e5e7eb' }} />}
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MenuDrawer;

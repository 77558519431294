import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerType } from '../types/customers';
import { CustomerServiceType } from '../types/services';
import { RootState } from '../store/store';

interface CustomersState {
  customers: CustomerType[];
  selectCustomer: CustomerType | null;
  loading: boolean;
  error: string | null;
}

const initialState: CustomersState = {
  customers: [],
  selectCustomer: null,
  loading: false,
  error: null,
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    fetchCustomersRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchCustomersSuccess: (state, action: PayloadAction<CustomerType[]>) => {
      state.loading = false;
      state.customers = action.payload;
    },
    fetchCustomersFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addCustomersRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    addCustomersSuccess: (state, action: PayloadAction<CustomerType[]>) => {
      state.loading = false;
      state.customers.push(...action.payload);
    },
    addCustomersFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteCustomerRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteCustomerSuccess: (state, action: PayloadAction<Number>) => {
      state.loading = false;
      const idToDelete = action.payload;
      state.customers = state.customers.filter(customer => customer.id !== idToDelete);
    },
    deleteCustomerFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    initCustomerServiceRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    initCustomerServiceSuccess: (state, action: PayloadAction<{ customer: CustomerType }>) => {
      state.loading = false;
      const updatedCustomer = action.payload.customer;
    
      // Mettre à jour le client dans la liste des clients
      const index = state.customers.findIndex(customer => customer.id === updatedCustomer.id);
      if (index !== -1) {
        state.customers = [
          ...state.customers.slice(0, index),
          updatedCustomer,
          ...state.customers.slice(index + 1),
        ];
      }
    
      // Mettre à jour le client sélectionné, s'il est sélectionné
      if (state.selectCustomer && state.selectCustomer.id === updatedCustomer.id) {
        state.selectCustomer = { ...updatedCustomer };
      }
    },
    
    initCustomerServiceFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    selectSelectedCustomer: (state, action: PayloadAction<CustomerType>) => {
      state.selectCustomer = action.payload;
    },
    updateCustomerRequest: (state ) => {
      state.loading = true;
      state.error = null;
    },
    updateCustomerSuccess: (state, action: PayloadAction<CustomerType>) => {
      state.loading = false;
      const updatedCustomer = action.payload;
      const index = state.customers.findIndex((customer) => customer.id === updatedCustomer.id);
      if (index !== -1) {
        state.customers[index] = updatedCustomer;
        if (state.selectCustomer && state.selectCustomer.id === updatedCustomer.id) {
          state.selectCustomer = updatedCustomer;
        }
      }
    },
    updateCustomerFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateCustomerServiceRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateCustomerServiceSuccess: (state, action: PayloadAction<CustomerServiceType>) => {
      state.loading = false;
      const updatedCustomerService = action.payload;

      // Mettre à jour le service client dans la liste des clients
      state.customers.forEach((customer) => {
        const index = customer.customerServices.findIndex((service) => service.id === updatedCustomerService.id);
        if (index !== -1) {
          customer.customerServices[index] = updatedCustomerService;
        }
      });

      // Mettre à jour le service client sélectionné, s'il est sélectionné
      if (state.selectCustomer) {
        const index = state.selectCustomer.customerServices.findIndex(
          (service) => service.id === updatedCustomerService.id
        );
        if (index !== -1) {
          state.selectCustomer.customerServices[index] = updatedCustomerService;
        }
      }
    },
    updateCustomerServiceFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteCustomerServiceRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    deleteCustomerServiceSuccess: (state, action: PayloadAction<{ customerId: number, customerServiceId: number }>) => {
      state.loading = false;
      const { customerId, customerServiceId } = action.payload;

      // Mettre à jour la liste des services du client
      const customer = state.customers.find(customer => customer.id === customerId);
      if (customer) {
        customer.customerServices = customer.customerServices.filter(service => service.id !== customerServiceId);
      }

      // Mettre à jour le client sélectionné, s'il est sélectionné
      if (state.selectCustomer && state.selectCustomer.id === customerId) {
        state.selectCustomer.customerServices = state.selectCustomer.customerServices.filter(service => service.id !== customerServiceId);
      }
    },
    deleteCustomerServiceFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchCustomersRequest, fetchCustomersSuccess, fetchCustomersFailure,
  addCustomersRequest, addCustomersSuccess, addCustomersFailure,
  deleteCustomerRequest, deleteCustomerSuccess, deleteCustomerFailure,
  initCustomerServiceRequest, initCustomerServiceSuccess, initCustomerServiceFailure,
  selectSelectedCustomer,
  updateCustomerRequest, updateCustomerSuccess, updateCustomerFailure,
  updateCustomerServiceRequest,updateCustomerServiceSuccess, updateCustomerServiceFailure,
  deleteCustomerServiceRequest,deleteCustomerServiceSuccess, deleteCustomerServiceFailure
 } = customersSlice.actions;

export const selectCustomersLoading = (state: RootState) => state.cust.loading;

export default customersSlice.reducer;

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Box, Typography, Container, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Fab, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import { RootState, useAppDispatch } from '../../redux/store/store';
import { QuoteType, QuoteRowType, QuoteStatusType } from '../../redux/types/quotes';
import AlertComponent from '../common/Alert';
import { useSelector } from 'react-redux';
import LoadingIndicator from '../common/LoadingIndicator';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { formatAddress } from '../../utils/utils';
import QuoteStepper from '../common/QuoteStepper';
import QuoteStatusSelect from '../common/QuoteStatusSelect';
import { addRowQuote, deleteQuote, deleteRowQuote, duplicateQuote, printQuote, updateQuote, updateStatusQuote } from '../../redux/actions/quotesActions';
import CustomModal from '../common/modal/CustomModal';
import AddServiceForm from '../Services/AddServiceForm';
import { ServiceType } from '../../redux/types/services';
import { fetchServices } from '../../redux/actions/servicesActions';
import ConfirmModal from '../common/modal/ConfirmModal';
import { useNavigate } from 'react-router-dom';
import { AdresseType, CustomerType } from '../../redux/types/customers';
import CustomerSelectionModal from '../Customer/CustomerSelectionModal';
import PelliculageModal from '../common/modal/services/PelliculageModal';
import DorureModal from '../common/modal/services/DorureModal';
import VernisModal from '../common/modal/services/VernisModal';
import DecoupeRainageModal from '../common/modal/services/DecoupeRainageModal';
import AutreModal from '../common/modal/services/AutreModal';
import EmballageModal from '../common/modal/services/EmballageModal';
import DosCarreColleModal from '../common/modal/services/DosCarreColleModal';
import PliageModal from '../common/modal/services/PliageModal';
import PiqureSimpleModal from '../common/modal/services/PiqureSimpleModal';
import PiqureDoubleModal from '../common/modal/services/PiqureDoubleCoupe';
import { setAlertWithDuration } from '../../redux/reducers/alertReducer';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  row: QuoteRowType;
  quoteId: number;
}

const modalComponents: Record<string, React.ComponentType<ModalProps>> = {
  'PELLICULAGE': PelliculageModal,
  'DORURE': DorureModal,
  'VERNIS': VernisModal,
  'DECOUPE/RAINAGE': DecoupeRainageModal,
  'AUTRE': AutreModal,
  'EMBALLAGE': EmballageModal,
  'DOS CARRE COLLE': DosCarreColleModal,
  'PLIAGE': PliageModal,
  'PIQURE SIMPLE': PiqureSimpleModal,
  'PIQURE DOUBLE COUPE': PiqureDoubleModal,
};

const QuoteDetailsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedQuote = useSelector((state: RootState) => state.quotes.selectQuote as QuoteType);
  const statues = useSelector((state: RootState) => state.quotes.statues as QuoteStatusType[]);
  const customers = useSelector((state: RootState) => state.cust.customers as CustomerType[]);
  const services = useSelector((state: RootState) => state.services.services as ServiceType[]);
  const userRole = useSelector((state: RootState) => state.auth.user?.role);
  const isAdmin = userRole === 'ADMIN';

  const [isEditMode, setIsEditMode] = useState(false);
  const [quoteDetails, setQuoteDetails] = useState<QuoteType>(selectedQuote);
  const [isAddServiceModalOpen, setIsAddServiceModalOpen] = useState(false);
  const [selectedServices, setSelectedServices] = useState<ServiceType[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [openCustomerSelectionModal, setOpenCustomerSelectionModal] = useState(false);
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<QuoteRowType | null>(null);
  const [showStatusConfirmation, setShowStatusConfirmation] = useState(false);

  const SelectedModal = selectedService ? modalComponents[selectedService] : null;

  const handleOpenCustomerSelectionModal = () => {
    setOpenCustomerSelectionModal(true);
  };

  const handleCustomerSelect = (customerId: number) => {
    if (quoteDetails.id !== undefined) {
      dispatch(duplicateQuote({ quoteId: quoteDetails.id, customerId: customerId }));
    }
    setOpenCustomerSelectionModal(false);
  };

  const handleRowClick = (row: QuoteRowType) => {
    setSelectedService(row.serviceName);
    setSelectedRow(row);
  };

  useEffect(() => {
    if (isAddServiceModalOpen) {
      setSelectedServices([]);
    }
  }, [isAddServiceModalOpen]);

  useEffect(() => {
    dispatch(fetchServices());
  }, [dispatch]);

  useEffect(() => {
    setQuoteDetails(selectedQuote);
  }, [selectedQuote]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (["createdBy", "createdAt", "updatedBy", "updatedAt"].includes(e.target.name)) {
      return;
    }
    setQuoteDetails({
      ...quoteDetails,
      [e.target.name]: e.target.value,
    });
  };

  const getCustomer = useCallback((customerId: number | undefined): CustomerType | undefined => {
    if (customerId === undefined) return undefined;
    return customers.find(c => c.id === customerId);
  }, [customers]);

  const handleDelete = () => {
    if (!isAdmin) {
      dispatch(setAlertWithDuration("Vous n'êtes pas autorisé a effectuer cette action.", "error", "403"));
    } else {
      setShowModal(true);
    }
  };

  const handleConfirm = async () => {
    setShowModal(false);
    if (quoteDetails.id !== undefined) {
      await dispatch(deleteQuote({ quoteId: quoteDetails.id }));
      navigate(`/quote`);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseServiceModal = () => {
    setSelectedService(null);
    setSelectedRow(null);
  };

  const handleDeleteRow = (rowId: number) => {
    dispatch(deleteRowQuote({ quoteId: quoteDetails.id || 0, rowId: rowId }));
  };

  const handlePrint = () => {
    if (quoteDetails.id !== undefined && quoteDetails.refAppStudio !== undefined) {
      dispatch(printQuote({ quoteId: quoteDetails.id, quoteReference: quoteDetails.refAppStudio }));
    }
  };

  const handleSave = () => {
    dispatch(updateQuote(quoteDetails));
    setIsEditMode(false);
  };

  const handleCardClick = (service: ServiceType) => {
    handleServiceToggle(service);
  };

  const handleServiceToggle = (service: ServiceType) => {
    setSelectedServices(prevServices =>
      prevServices.find(selectedService => selectedService.id === service.id)
        ? prevServices.filter(selectedService => selectedService.id !== service.id)
        : [...prevServices, service]
    );
  };

  const handleClose = () => {
    const newRows: QuoteRowType[] = selectedServices.map((service) => ({
      serviceId: service.id || 0,
      quoteId: quoteDetails.id || 0,
      serviceName: service.name,
      description: "",
    }));
    if (quoteDetails.id !== undefined) {
      dispatch(addRowQuote({ quoteId: quoteDetails.id, newRows: newRows }));
      setIsAddServiceModalOpen(false);
    }
  };

  const handleSelectStatus = (statusId: number) => {
    if (quoteDetails.id !== undefined) {
      const newStatus = statues.find((status: QuoteStatusType) => status.id === statusId);
      if (newStatus) {
        setQuoteDetails({
          ...quoteDetails,
          status: newStatus,
        });
        dispatch(updateStatusQuote({ quoteId: quoteDetails.id, quoteStatusId: statusId }));
      }
    }
  };

  const handleAddRow = () => {
    setIsAddServiceModalOpen(true);
  };

  const sortedRows = useMemo(() =>
    quoteDetails.rows?.slice().sort((a, b) => (b.id || 0) - (a.id || 0)),
    [quoteDetails.rows]
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        <AlertComponent />
        <LoadingIndicator />
        <Container maxWidth="xl">
          <Box textAlign="center" my={4}>
            <Typography variant="h2" gutterBottom>
              Devis {quoteDetails.refAppStudio}
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Box textAlign="left" my={4}>
                  <Typography variant="h4" gutterBottom>
                    Informations
                  </Typography>
                  {!isEditMode ? (
                    <Box>
                      <Typography variant="body2">
                        Détails des prix: {quoteDetails.showPriceService ? 'Activé' : 'Désactivé'}
                      </Typography>
                      <Typography variant="body2">Auteur : {quoteDetails.createdBy}</Typography>
                      <Typography variant="body2">Mis à jour : {quoteDetails.updatedBy} le {new Date(quoteDetails.updatedAt ?? new Date()).toLocaleDateString()}</Typography>
                    </Box>
                  ) : (
                    <Box>
                      <FormControlLabel label="Afficher le détails des prix" sx={{ mb: 2 }}
                        control={
                          <Checkbox
                            checked={quoteDetails.showPriceService}
                            onChange={handleChange}
                          />}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box textAlign="center" my={4}>
                  <Typography variant="h4">Total: {quoteDetails.totalAmount || 0}€</Typography>
                  <Typography variant="h4">Echéance: {new Date(quoteDetails.expirationDate ?? new Date()).toLocaleDateString()}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box textAlign="right" my={4}>
                  <Typography variant="h4" gutterBottom>
                    Client
                  </Typography>
                  {quoteDetails.customerId !== undefined ? (
                    <>
                      <Typography variant="body2">{getCustomer(quoteDetails.customerId)?.name || "Inconnu"}</Typography>
                      <Typography variant="body2">
                        {getCustomer(quoteDetails.customerId) ?
                          (getCustomer(quoteDetails.customerId)?.address ?
                            formatAddress(getCustomer(quoteDetails.customerId)?.address as AdresseType) : "N/A") : "Inconnu"}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="body2">Client inconnu</Typography>
                  )}
                  <Typography variant="body2">Référence client: {quoteDetails.customerReference || 'N/A'}</Typography>
                </Box>
              </Grid>
            </Grid>
            <QuoteStepper statuses={statues} activeStep={quoteDetails.status} />
            <QuoteStatusSelect statuses={statues} onSelectStatus={handleSelectStatus} currentStatusId={quoteDetails.status.id} />
          </Box>
          <Box textAlign="right" p={2}>
            <Button variant="contained" size="small" color="primary" onClick={handleAddRow}>
              Nouvelle ligne
            </Button>
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Service</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Nombre d'exemplaire</TableCell>
                    <TableCell>Montant</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedRows?.map((row, index) => (
                    <TableRow key={index} onClick={() => handleRowClick(row)}>
                      <TableCell>{row.serviceName}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>{row.sheetsNumber || 0}</TableCell>
                      <TableCell>{row.price || 0}€</TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <IconButton aria-label="delete" onClick={() => handleDeleteRow(row.id || 0)}>
                          <DeleteIcon sx={{ color: '#d32f2f' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {isEditMode ? (
            <Box sx={{ position: 'absolute', bottom: 16, right: 16, '& > :not(style)': { m: 1 } }}>
              <Fab color="secondary" aria-label="cancel" onClick={() => setIsEditMode(false)}>
                <CancelIcon />
              </Fab>
              <Fab color="primary" aria-label="save" onClick={handleSave}>
                <SaveIcon />
              </Fab>
            </Box>
          ) : (
            <SpeedDial
              ariaLabel="SpeedDial"
              sx={{ position: 'absolute', bottom: 16, right: 16 }}
              icon={<SpeedDialIcon />}
            >
              <SpeedDialAction
                icon={<EditIcon />}
                tooltipTitle="Modifier"
                onClick={() => setIsEditMode(true)}
              />
              <SpeedDialAction
                icon={<DeleteIcon />}
                tooltipTitle="Supprimer"
                onClick={handleDelete}
              />
              <SpeedDialAction
                icon={<PrintIcon />}
                tooltipTitle="Imprimer"
                onClick={handlePrint}
              />
              <SpeedDialAction
                icon={<FileCopyIcon />}
                tooltipTitle="Dupliquer"
                onClick={handleOpenCustomerSelectionModal}
              />
            </SpeedDial>
          )}
        </Container>
        <CustomModal
          open={isAddServiceModalOpen}
          title=""
          onClose={() => setIsAddServiceModalOpen(false)}
        >
          <AddServiceForm
            services={services}
            selectedServices={selectedServices}
            handleCardClick={handleCardClick}
            handleServiceToggle={handleServiceToggle}
          />
          <Box textAlign="center">
            <Button onClick={handleClose} variant="contained" color="primary" fullWidth>
              Ajouter
            </Button>
          </Box>
        </CustomModal>
        <ConfirmModal open={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirm} />
        <CustomerSelectionModal
          open={openCustomerSelectionModal}
          onClose={() => setOpenCustomerSelectionModal(false)}
          customers={customers}
          onSelectCustomer={handleCustomerSelect}
        />
        {SelectedModal && selectedRow && (
          <SelectedModal open={true} handleClose={handleCloseServiceModal} row={selectedRow} quoteId={quoteDetails.id || 0} />
        )}
        <ConfirmModal
          open={showStatusConfirmation}
          handleClose={() => setShowStatusConfirmation(false)}
          handleConfirm={() => {
            if (quoteDetails.id !== undefined && quoteDetails.refAppStudio !== undefined) {
              dispatch(updateStatusQuote({ quoteId: quoteDetails.id, quoteStatusId: 4 }));
            }
            setShowStatusConfirmation(false);
          }}
          title="Changer le statut du devis à 'Envoyé'"
          message="Voulez-vous changer le statut du devis à 'Envoyé' avant d'imprimer ?"
        />
      </Box>
    </Box>
  );
};

export default QuoteDetailsPage;
